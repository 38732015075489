import React from 'react'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'



interface CardProperties {
    value: number | undefined,
    description: string | undefined,
    value_previous: number | undefined,
    isPercent: boolean | undefined
}

const getPercentageIncrease = (value: number, valuePrevious: number, isPercent: boolean) =>
    (((value - valuePrevious) / valuePrevious)).toFixed(2) + ((isPercent) ? '%' : '')


const CardDashboad: React.FC<CardProperties> = ({ value, description, value_previous, isPercent=false }) => {
    return (
        <>
            <Card >
                <CardContent>
                    <Typography variant="h2" component="h2" align="center">
                        {value ? value : '0'}
                    </Typography>
                    <Typography color="textSecondary" align="center">
                        {value ? description : 'No hay nada todavía.'}
                    </Typography>
                    <Typography align="center">

                        {(value_previous && value && value >= value_previous)
                            ? <ArrowUpwardIcon style={{ color: 'green', verticalAlign: 'sub' }} />
                            : value_previous && value && < ArrowDownwardIcon style={{ color: 'red', verticalAlign: 'sub' }} />}
                        {value_previous && value ? getPercentageIncrease(value, value_previous, isPercent) : ''}

                    </Typography>
                </CardContent>
            </Card>
        </>
    )
}

export { CardDashboad }
const DATE_FORMAT = 'DD/MM/YYYY'
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
const TABLE_COLUMN_NOT_DEFINED = '---'
const PASSWORD_MIN_LENGTH = 8

export const STRINGS = {
  DATE_FORMAT,
  TABLE_COLUMN_NOT_DEFINED,
  PASSWORD_MIN_LENGTH,
  DATE_TIME_FORMAT
}

import { AxiosRequestConfig } from 'axios'

import { LoginPayload } from './types'

const login = (payload: LoginPayload): AxiosRequestConfig => ({
  url: '/auth/login/admin',
  method: 'POST',
  data: {
    ...payload,
  },
})

export { login }

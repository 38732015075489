import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { StringUtils } from 'utils'

import { CreateDrawerItemResponse, DrawerItem, CreateDrawerItemPayload } from '../../types'
import { createDrawerItem } from '../../api'
import { useStyles } from './styles'

const rules = {
  name: [
    {
      validate: (name: string) => !!name,
      message: 'Este campo es requerido.',
    },
  ],
  redirectUrl: [
    {
      validate: (url: string) => StringUtils.isURL(url),
      message: `Debe ser una URL válida.`,
    },
  ],
  logo: [],
}

interface Props {
  handleClose: () => void
  onDone: (newDrawerItem: DrawerItem) => void
}

const CreateDrawerItemDialog: React.FC<Props> = ({ handleClose, onDone }) => {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [redirectUrl, setRedirectUrl] = useState('')
  const [logo, setLogo] = useState<File | null>(null)
  const [logoPreview, setLogoPreview] = useState('')

  const [createDrawerItemApi, isLoading] = useApiCall<
    CreateDrawerItemPayload,
    CreateDrawerItemResponse
  >(createDrawerItem)

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setName(e.target.value)
  }

  const handleRedirectUrlChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setRedirectUrl(e.target.value)
  }

  const handleChangeLogo = (file: File) => {
    setLogo(file)
    setLogoPreview(URL.createObjectURL(file))
  }

  const fields = { name, redirectUrl, logo }
  const { errors, hasErrors, isAnyFieldEmpty } = useCheckFormErrors(fields, rules)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onCreateDrawerItem = async () => {
    if (!logo) return
    const newItem = { name, redirectUrl, logo }
    try {
      const createdItem: CreateDrawerItemResponse = await createDrawerItemApi(newItem)
      onDone(createdItem.drawerItem)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo crear link. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Nuevo link mobile"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateDrawerItem}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Nombre"
            value={name}
            fullWidth
            onChange={handleNameChange}
            error={errors.name.hasError}
            helperText={errors.name.message}
            disabled={isLoading}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            inputProps={{ style: { textOverflow: 'ellipsis' } }}
            label="URL de redirección"
            fullWidth
            value={redirectUrl}
            onChange={handleRedirectUrlChange}
            error={errors.redirectUrl.hasError}
            helperText={errors.redirectUrl.message}
            disabled={isLoading}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <UploadButton
            id="DI-logo-upload-button"
            accept=".jpg, .jpeg, .png"
            label="Subir logo"
            onChange={handleChangeLogo}
          />
          <img src={logoPreview} alt="" className={classes.imagePreview} />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateDrawerItemDialog }

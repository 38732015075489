import React, { useState } from 'react'
import { Moment } from 'moment'
import { Grid, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { STRINGS } from 'consts'

import { UserPoll, CreateUserPollPayload, CreateUserPollResponse } from '../../types'
import { createUserPoll } from '../../api'
import { useStyles } from './styles'
import { constants } from '../../constansts'

const rules = {
  question: [
    {
      validate: (value: string) => !value || value.length <= 254,
      message: 'No puede superar los 254 caracteres',
    },
  ]
}

const requiredFields = ['question', 'active']

interface CreateUserPollDialogProps {
  handleClose: () => void
  onDone: (newUserPoll: UserPoll) => void
}

const CreateUserPollDialog: React.FC<CreateUserPollDialogProps> = ({ handleClose, onDone }) => {
  const classes = useStyles()

  const [question, setQuestion] = useState('')
  const [active, setActive] = useState(false)

  const [createUserPollApi, isLoading] = useApiCall<CreateUserPollPayload, CreateUserPollResponse>(
    createUserPoll,
  )

  const handleQuestionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setQuestion(e.target.value)
  }

  const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active);
  }

  const fields = { question, active }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onCreateUserPoll = async () => {
    if (!question) return
    const userPoll = {
      question,
      active
    }
    try {
      const { userPoll: newUserPoll } = await createUserPollApi(userPoll)
      onDone(newUserPoll)
    } catch (err) {
      console.error(err)
      snackbar.show(`No se pudo crear ${constants.TEST_NAME}. Intente de nuevo.`)
    }
  }

  return (
    <Dialog
      title={`Nuevo ${constants.TEST_NAME}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateUserPoll}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Pregunta"
            value={question}
            fullWidth
            onChange={handleQuestionChange}
            disabled={isLoading}
            multiline
            error={errors.question.hasError}
            helperText={errors.question.message}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateUserPollDialog }

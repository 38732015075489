import { AxiosRequestConfig } from 'axios'

import { CreateManualRewardPayload, UpdateManualRewardData } from './types'

const getManualRewards = (): AxiosRequestConfig => ({
  url: '/prizes/manual-rewards',
  method: 'GET',
})

const getUserTestTagData = (): AxiosRequestConfig => ({
  url: '/prizes/manual-rewards/user-test-data',
  method: 'GET',
})

const createManualReward = (manualReward: CreateManualRewardPayload): AxiosRequestConfig => {
  //const payload = {} as CreateManualRewardPayload
  const payload = new FormData()
  const { title, shortTitle, icon, image, active, pushMessage, pushTitle, pushDescription, tagsSelected, expiration, additionalText, allTagsAreRequired } = manualReward

  payload.append('icon', icon)
  if (image) payload.append('image', image)
  payload.append('title', title)
  payload.append('shortTitle', shortTitle)
  payload.append('pushMessage', pushMessage.toString())
  if (pushTitle) payload.append('pushTitle', pushTitle)
  if (pushDescription) payload.append('pushDescription', pushDescription)
  payload.append('active', active.toString())
  payload.append('expiration', expiration)
  if (additionalText) payload.append('additionalText', additionalText)

  if (tagsSelected) payload.append('tags', tagsSelected.map(el => el.value).join(','))
  payload.append('allTagsAreRequired', allTagsAreRequired.toString())

  return {
    url: '/prizes/manual-rewards',
    method: 'POST',
    data: payload,
  }
}

const updateManualReward = (data: UpdateManualRewardData): AxiosRequestConfig => {
  const payload = new FormData()
  const { title, shortTitle, active, icon, image, pushMessage, pushTitle, pushDescription, tagsSelected, expiration, additionalText, allTagsAreRequired } = data.payload
  if (title) payload.append('title', title)
  if (shortTitle) payload.append('shortTitle', shortTitle)
  if (icon) payload.append('icon', icon)
  if (image) payload.append('image', image)
  if (pushMessage !== undefined) payload.append('pushMessage', pushMessage.toString())
  if (pushTitle) payload.append('pushTitle', pushTitle.toString())
  if (pushDescription) payload.append('pushDescription', pushDescription.toString())
  if (tagsSelected) payload.append('tags', tagsSelected.map(el => el.value).join(','))
  if (active !== undefined) payload.append('active', active.toString())
  if (expiration) payload.append('expiration', expiration)
  if (additionalText) payload.append('additionalText', additionalText.toString())
  if (allTagsAreRequired !== undefined) payload.append('allTagsAreRequired', allTagsAreRequired.toString())

  return {
    url: `/prizes/manual-rewards/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const deleteManualReward = (id: number): AxiosRequestConfig => ({
  url: `/prizes/manual-rewards/${id}`,
  method: 'DELETE',
})

const processManualReward = (id: number): AxiosRequestConfig => ({
  url: `/prizes/manual-rewards/${id}/process`,
  method: 'POST',
})

export { getManualRewards, createManualReward, updateManualReward, deleteManualReward, getUserTestTagData, processManualReward }

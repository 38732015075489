import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'

import { Tag, CreateTagPayload, CreateTagResponse } from '../../types'
import { createTag } from '../../api'
import { useStyles } from './styles'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    }
  ],
  description: [
    {
      validate: (description: string) => description.length <= 200,
      message: 'No puede superar los 200 caracteres',
    },
  ]
}

interface CreateTagDialogProps {
  handleClose: () => void
  onDone: (newTag: Tag) => void
}

const CreateTagDialog: React.FC<CreateTagDialogProps> = ({ handleClose, onDone }) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(false)

  const [createTagApi, isLoading] = useApiCall<CreateTagPayload, CreateTagResponse>(
    createTag,
  )

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setDescription(e.target.value)
  }

  const handleActiveChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active)
  }

  const fields = { title, description }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(fields, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const onCreateTag = async () => {
    if (!title || !description) return
    const tag = {
      title,
      description,
      active
    }
    try {
      const { tag: newTag } = await createTagApi(tag)
      onDone(newTag)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo crear etiqueta(tag). Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Nueva etiqueta(tag)"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateTag}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <TextField
            label="Título"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
            inputProps={{ maxLength: 25 }}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Descripción"
            value={description}
            fullWidth
            onChange={handleDescriptionChange}
            disabled={isLoading}
            required
            error={errors.description.hasError}
            helperText={errors.description.message}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleActiveChange}
              checked={active}
            />}
            label="Activo"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateTagDialog }

import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import Divider from '@material-ui/core/Divider';
import { InputLabel } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MultiSelect } from "react-multi-select-component";

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import moment, { Moment } from 'moment'

import { updateNotification } from '../api'
import { UpdateNotificationData, Notification, UpdateNotificationResponse } from '../types'
import { Tag } from 'features/Main/Tags/types'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
}

interface UpdateNotificationDialogProps {
  notification: Notification
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const UpdateNotificationDialog: React.FC<UpdateNotificationDialogProps> = ({ notification, handleClose, onDone, tags, calcUsersByTags }) => {
  const [title, setTitle] = useState(notification.title)
  const [body, setBody] = useState(notification.body)
  const [scheduled, setScheduled] = useState<Moment | null>(moment(notification.scheduled))
  const data = { title, body }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors || notification.sent
  const [tagsSelected, setTagsSelected] = useState<any[]>([]);
  const [usersByTags, setUsersByTags] = useState(0)
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(notification.allTagsAreRequired)
  
  const [updateNotificationApi, isLoading] = useApiCall<UpdateNotificationData, UpdateNotificationResponse>(updateNotification)
  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))
  
  useEffect(() => {
    if (!notification.tags) return
    
    const localTagsSelected = notification.tags.map(el => ({ label: el.title, value: el.id }))
    setTagsSelected(localTagsSelected)
    
    const getUsersByTags = async () => {
      return await calcUsersByTags(localTagsSelected, allTagsAreRequired);
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
  }, [])

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))    
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async (e) => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)    
    setUsersByTags(total)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired
    }
    try {
      const { notification: updatedNotification } = await updateNotificationApi({ id: notification.id, payload: data })
      onDone(updatedNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title={notification!.sent ? `No es posible actualizar una notificación ya enviada` : `Editar notificación`}
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      {!notification!.sent && <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            onChange={e => setBody(e.target.value)}
            value={body}
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            value={scheduled}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_TIME_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Programado"
            minDateMessage="La fecha de programación no puede ser anterior a hoy"
          />
        </Grid>
        <Grid item xs={11}>
          <InputLabel>Tags</InputLabel>
          <MultiSelect
            options={tagsForSelect}
            value={tagsSelected}
            onChange={handleSetTags}
            labelledBy={"Select"}
            hasSelectAll={false}
          />
        </Grid>
        <Grid item xs={11}>
          <FormControlLabel
            control={<Checkbox onChange={handleAllTagsAreRequiredChange}
              checked={allTagsAreRequired}
            />}
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={11}><Divider variant="middle" /></Grid>
        <Grid item xs={11}>
          <InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
        </Grid>
      </Grid>
      }
    </Dialog>
  )
}

export { UpdateNotificationDialog }

import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'
import moment from 'moment'

import { getNotifications } from './api'
import { DefaultTable } from '../components'
import { GetNotificationsResponse, Notification } from './types'
import { CreateNotificationDialog, DeleteNotificationDialog, UpdateNotificationDialog, DuplicateNotificationDialog } from './components'

import TextField from '@material-ui/core/TextField'
import { GetTagsResponse, GetTotalUsersWithAllTagsPayload, GetTotalUsersWithAllTagsResponse, Tag } from '../Tags/types';
import { getActiveTags, getTotalUsersWithAllTags } from '../Tags/api';
import { GetUserTestTagData, GetUserTestTagResponse } from '../ManualRewards/types';
import { getUserTestTagData } from '../ManualRewards/api';
import calculateUserTestByTags from '../Helpers/userTestHelper';

const columns = [
  { title: 'ID', field: 'id', filtering: false },
  { title: 'Título', field: 'title' },
  { title: 'Mensaje', field: 'body' },
  {
    title: 'Tags',
    render: (rowData: Notification) => rowData.tags?.map((tag) => tag.title).join(', ')
  },
  {
    title: 'Tags requeridos',
    render: (rowData: Notification) => (rowData.allTagsAreRequired ? 'Sí' : 'No'),
    sorting: false,
  },
  {
    title: 'Programada',
    render: (rowData: Notification) => (
      <table>
        <tbody>
          <tr>
            <td
              style={{
                color: moment().isAfter(rowData.scheduled) ? 'red' : 'black',
              }}
            >
              {moment(rowData.scheduled).format(STRINGS.DATE_TIME_FORMAT)}
            </td>
          </tr>
        </tbody>
      </table>
    ),
    customSort: (a: Notification, b: Notification) =>
      !a.scheduled || moment(a.scheduled).isAfter(moment(b.scheduled)) ? 1 : -1,
  },
  {
    title: 'Enviada',
    render: (rowData: Notification) => rowData.sent ? <>SI</> : <>NO</>,
    field: 'sent',
    lookup: { true: 'SI', false: 'NO' }
  },
]

const getNotificationsResponseGetter = (responseData: GetNotificationsResponse) => responseData?.notifications ?? []
const getTagsResponseGetter = (responseData: GetTagsResponse) => responseData?.tags ?? []
const getUserTestTagResponseGetter = (responseData: GetUserTestTagResponse) => responseData?.userTestTagData ?? []

const Notifications: React.FC = () => {
  const [{ data: notifications, isLoading }, , setNotifications] = useApi<GetNotificationsResponse, Notification[]>(
    getNotifications,
    getNotificationsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )
  const [{ data: tags }, , setTags] = useApi<GetTagsResponse, Tag[]>(
    getActiveTags,
    getTagsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )
  const [{ data: userTestTagData }, , setUserTestTagData] = useApi<GetUserTestTagResponse, GetUserTestTagData[]>(
    getUserTestTagData,
    getUserTestTagResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [getTotalUsersWithAllTagsApi] = useApiCall<GetTotalUsersWithAllTagsPayload, GetTotalUsersWithAllTagsResponse>(
    getTotalUsersWithAllTags,
  )

  const handleCalculateUserTestByTags = async (tags: any[], allTagsAreRequired: boolean = false): Promise<number> => {
    if (!allTagsAreRequired)
      return calculateUserTestByTags(userTestTagData, tags);

    if (!tags.length) return 0
    return await getTotalUsersWithAllTagsApi({tagIds: tags.map(el => el.value)}).then(e => e.total);
  }

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [notificationToDelete, setNotificationToDelete] = useState<Notification | null>(null)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [notificationToUpdate, setNotificationToUpdate] = useState<Notification | null>(null)
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false)
  const [notificationToDuplicate, setNotificationToDuplicate] = useState<Notification | null>(null)
  
  const onCreateDone = (newNotification: Notification) => {
    setNotifications([newNotification, ...notifications])
    setIsCreateDialogOpen(false)
  }

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
    setNotificationToDelete(null)
  }

  const onDeleteDone = () => {
    if (!notificationToDelete) return
    setNotifications(notifications.filter(notification => notification.id !== notificationToDelete.id))
    closeDeleteDialog()
  }

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false)
    setNotificationToUpdate(null)
  }

  const closeDuplicateDialog = () => {
    setIsDuplicateDialogOpen(false)
    setNotificationToDuplicate(null)
  }

  const onDuplicateDone = (newNotification: Notification) => {
    setNotifications([...notifications, newNotification])
    setIsDuplicateDialogOpen(false)
  }

  const onUpdateDone = (newNotification: Notification) => {
    setNotifications(
      notifications.map(notification => {
        if (notification.id === newNotification.id) return newNotification
        return notification
      }),
    )
    closeUpdateDialog()
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nueva notificación',
      isFreeAction: true,
      onClick: () => setIsCreateDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar notificación',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, notificacion: Notification | Notification[]) => {
        setNotificationToUpdate(notificacion as Notification)
        setIsUpdateDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar notificación',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, notification: Notification | Notification[]) => {
        setNotificationToDelete(notification as Notification)
        setIsDeleteDialogOpen(true)
      },
    },
    {
      icon: () => <FileCopyIcon />,
      tooltip: 'Duplicar notificación',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, notificacion: Notification | Notification[]) => {
        setNotificationToDuplicate(notificacion as Notification)
        setIsDuplicateDialogOpen(true)
      },
    },
  ]

  return (
    <>
      <DefaultTable
        title="Notificaciones"
        columns={columns}
        data={notifications}
        isLoading={isLoading}
        actions={actions}
        options={{
          filtering: true,
          search: false
        }}
      />
      {isCreateDialogOpen && (
        <CreateNotificationDialog
          handleClose={() => setIsCreateDialogOpen(false)}
          onDone={onCreateDone}
          tags={tags}
          calcUsersByTags={handleCalculateUserTestByTags}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteNotificationDialog
          notification={notificationToDelete}
          handleClose={closeDeleteDialog}
          onDone={onDeleteDone}
        />
      )}
      {isUpdateDialogOpen && notificationToUpdate && (
        <UpdateNotificationDialog
          notification={notificationToUpdate}
          handleClose={closeUpdateDialog}
          onDone={onUpdateDone}
          tags={tags}
          calcUsersByTags={handleCalculateUserTestByTags}
        />
      )}
      {isDuplicateDialogOpen && notificationToDuplicate && (
        <DuplicateNotificationDialog
          notification={notificationToDuplicate}
          handleClose={closeDuplicateDialog}
          onDone={onDuplicateDone}
          tags={tags}
          calcUsersByTags={handleCalculateUserTestByTags}
        />
      )}
    </>
  )
}

export { Notifications }

import React from 'react'
import { MaterialTableProps, Column } from 'material-table'
import EditIcon from '@material-ui/icons/Edit'
import EditSelectionIcon from '@material-ui/icons/EditAttributes'
import EditOrderIcon from '@material-ui/icons/ImportExport'

import { STRINGS } from 'consts'
import { ExternalLink } from 'components'

import { DefaultTable } from '../../components'
import { DrawerItem } from '../types'

const columns: Column<DrawerItem>[] = [
  { title: 'Posición', field: 'position', defaultSort: 'asc' },
  { title: 'Nombre', field: 'name', sorting: false },
  {
    title: 'Nativo',
    render: (row: DrawerItem) => (row.native ? 'Sí' : 'No'),
    sorting: false,
  },
  {
    title: 'Seleccionado',
    render: (row: DrawerItem) => (row.selected ? 'Sí' : 'No'),
    sorting: false,
  },
  {
    title: 'Logo',
    render: (row: DrawerItem) =>
      row.logoUrl ? (
        <img src={row.logoUrl} alt="" style={{ width: 20 }} />
      ) : (
        STRINGS.TABLE_COLUMN_NOT_DEFINED
      ),
    sorting: false,
  },
  {
    title: 'URL de redirección',

    render: (row: DrawerItem) =>
      row.redirectUrl ? (
        <ExternalLink href={row.redirectUrl}>{row.redirectUrl}</ExternalLink>
      ) : (
        STRINGS.TABLE_COLUMN_NOT_DEFINED
      ),
    sorting: false,
    cellStyle: {
      wordBreak: 'break-word',
    },
  },
]

type DrawerItemsTableProps = Omit<
  MaterialTableProps<DrawerItem>,
  'title' | 'columns' | 'actions'
> & {
  handleCreate: () => void
  handleUpdate: (event: React.SyntheticEvent, item: DrawerItem | DrawerItem[]) => void
  handleDelete: (event: React.SyntheticEvent, item: DrawerItem | DrawerItem[]) => void
  handleEditSelected: () => void
  handleEditOrder: () => void
}

const DrawerItemsTable: React.FC<DrawerItemsTableProps> = ({
  isLoading,
  data,
  handleCreate,
  handleUpdate,
  handleDelete,
  handleEditSelected,
  handleEditOrder,
}) => {
  const actions = [
    {
      icon: 'add',
      tooltip: 'Nuevo link',
      isFreeAction: true,
      onClick: handleCreate,
    },
    (row: DrawerItem) => ({
      icon: () => <EditIcon />,
      tooltip: 'Editar link',
      isFreeAction: false,
      onClick: handleUpdate,
      hidden: row.native,
    }),
    (row: DrawerItem) => ({
      icon: 'delete',
      tooltip: `Eliminar`,
      isFreeAction: false,
      onClick: handleDelete,
      hidden: row.native,
    }),
    {
      icon: () => <EditSelectionIcon />,
      tooltip: `Elegir links que irán en inicio`,
      isFreeAction: true,
      onClick: handleEditSelected,
    },
    {
      icon: () => <EditOrderIcon />,
      tooltip: `Cambiar orden de links`,
      isFreeAction: true,
      onClick: handleEditOrder,
    },
  ]

  return (
    <DefaultTable<DrawerItem>
      title="Links mobile"
      isLoading={isLoading}
      columns={columns}
      data={data}
      actions={actions}
    />
  )
}

export { DrawerItemsTable }

import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'

import { ERRORS } from 'consts'
import { useApi } from 'hooks'
import { snackbar } from 'components'

import { getStores } from './api'
import { DefaultTable } from '../components'
import { GetStoresResponse, Store } from './types'
import { CreateStoresDialog, DeleteStoreDialog, UpdateStoreDialog } from './components'

const columns = [
  { title: 'ID', field: 'id' },
  { title: 'Nombre', field: 'name' },
  { title: 'Dirección', field: 'address' },
]

const getStoresResponseGetter = (responseData: GetStoresResponse) => responseData?.stores ?? []

const Stores: React.FC = () => {
  const [{ data: stores, isLoading }, , setStores] = useApi<GetStoresResponse, Store[]>(
    getStores,
    getStoresResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [storeToDelete, setStoreToDelete] = useState<Store | null>(null)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [storeToUpdate, setStoreToUpdate] = useState<Store | null>(null)

  const onCreateDone = (newStore: Store) => {
    setStores([...stores, newStore])
    setIsCreateDialogOpen(false)
  }

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
    setStoreToDelete(null)
  }

  const onDeleteDone = () => {
    if (!storeToDelete) return
    setStores(stores.filter(store => store.id !== storeToDelete.id))
    closeDeleteDialog()
  }

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false)
    setStoreToUpdate(null)
  }

  const onUpdateDone = (newStore: Store) => {
    setStores(
      stores.map(store => {
        if (store.id === newStore.id) return newStore
        return store
      }),
    )
    closeUpdateDialog()
  }

  const actions = [
    {
      icon: 'add',
      tooltip: 'Nuevo local',
      isFreeAction: true,
      onClick: () => setIsCreateDialogOpen(true),
    },
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar local',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, store: Store | Store[]) => {
        setStoreToUpdate(store as Store)
        setIsUpdateDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: 'Eliminar local',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, store: Store | Store[]) => {
        setStoreToDelete(store as Store)
        setIsDeleteDialogOpen(true)
      },
    },
  ]

  return (
    <>
      <DefaultTable
        title="Locales"
        columns={columns}
        data={stores}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateDialogOpen && (
        <CreateStoresDialog
          handleClose={() => setIsCreateDialogOpen(false)}
          onDone={onCreateDone}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteStoreDialog
          store={storeToDelete}
          handleClose={closeDeleteDialog}
          onDone={onDeleteDone}
        />
      )}
      {isUpdateDialogOpen && storeToUpdate && (
        <UpdateStoreDialog
          store={storeToUpdate}
          handleClose={closeUpdateDialog}
          onDone={onUpdateDone}
        />
      )}
    </>
  )
}

export { Stores }

import { AxiosRequestConfig } from 'axios'

import { CreateStorePayload, UpdateStoreData } from './types'

const getStores = (): AxiosRequestConfig => ({
  url: '/stores',
  method: 'GET',
})

const createStore = (payload: CreateStorePayload): AxiosRequestConfig => ({
  url: '/stores',
  method: 'POST',
  data: { store: payload },
})

const updateStore = (data: UpdateStoreData): AxiosRequestConfig => ({
  url: `/stores/${data.id}`,
  method: 'PUT',
  data: { store: data.payload },
})

const deleteStore = (id: number): AxiosRequestConfig => ({
  url: `/stores/${id}`,
  method: 'DELETE',
})

export { getStores, createStore, updateStore, deleteStore }

import React from 'react'

import { NavBar, Drawer } from 'components'

import { useStyles } from './styles'

const Layout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavBar isLoggedIn />
      <Drawer />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export { Layout }

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, GooglePlacesAutocomplete, snackbar } from 'components'
import { Place } from 'components/GooglePlacesAutocomplete/types'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS } from 'consts'

import { updateStore } from '../api'
import { UpdateStoreData, Store } from '../types'

const rules = {
  address: [],
  name: [
    {
      validate: (name: string) => name.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  location: [],
}

interface UpdateStoreDialogProps {
  store: Store
  handleClose: () => void
  onDone: (newStore: Store) => void
}

const UpdateStoreDialog: React.FC<UpdateStoreDialogProps> = ({ store, handleClose, onDone }) => {
  const [address, setAddress] = useState(store.address)
  const [name, setName] = useState(store.name)
  const [location, setLocation] = useState<[number, number]>(store.location)

  const data = { address, name, location }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [updateStoreApi, isLoading] = useApiCall<UpdateStoreData, void>(updateStore)

  const onSelectedPlace = (place: Place) => {
    setAddress(place.address)
    setLocation([place.lat, place.lng])
  }

  const handleSubmit = async () => {
    try {
      await updateStoreApi({ id: store.id, payload: data })
      onDone({ id: store.id, address, name, location })
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title="Editar local"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar local"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre"
            required
            onChange={e => setName(e.target.value)}
            value={name}
            error={errors.name.hasError}
            helperText={errors.name.message}
          />
        </Grid>
        <Grid item xs={12}>
          <GooglePlacesAutocomplete onSelect={onSelectedPlace} initialValue={address} required />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateStoreDialog }

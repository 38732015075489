import React, { useState } from 'react'
import moment from 'moment'
import EditIcon from '@material-ui/icons/Edit'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BarChartIcon from '@material-ui/icons/BarChart';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getUserPolls, updateUserPollOrder } from './api'
import { constants } from './constansts'
import { DefaultTable } from '../components'
import { GetUserPollsResponse, UserPoll } from './types'
import { CreateUserPollDialog, DeleteUserPollDialog, UpdateUserPollDialog, UserPollDetailDialog } from './components'
import { GetTagsResponse, Tag } from '../Tags/types';
import { getActiveTags } from '../Tags/api';
import { UserPollOrderDialog } from './components/UserPollOrderDialog';
import { StatisticsUserPollDialog } from './components/StatisticsUserPollDialog';

const columns = [
  { title: 'Pregunta', field: 'question' },
  { title: 'Orden', field: 'order' },
  { title: 'Respondidas', field: 'answered' },
  { title: 'Estado', render: (rowData: UserPoll) => (rowData.active ? 'Activo' : 'Inactivo') },
]

const getUserPollsResponseGetter = (responseData: GetUserPollsResponse) => responseData?.userPolls ?? []
const getTagsResponseGetter = (responseData: GetTagsResponse) => responseData?.tags ?? []

const UserPolls: React.FC = () => {
  const [{ data: userPolls, isLoading }, refetchUserPolls, setUserPolls] = useApi<
    GetUserPollsResponse,
    UserPoll[]
  >(getUserPolls, getUserPollsResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })
  const [{ data: tags }, , setTags] = useApi<GetTagsResponse, Tag[]>(
    getActiveTags,
    getTagsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [updateUserPollOrderApi] = useApiCall<number[], void>(updateUserPollOrder)

  const [isCreateUserPollDialogOpen, setIsCreateUserPollDialogOpen] = useState(false)
  const [isStatisticsDialogOpen, setStatisticsDialogOpen] = useState(false)
  const [isDeleteUserPollDialogOpen, setIsDeleteUserPollDialogOpen] = useState(false)
  const [userPollToDelete, setUserPollToDelete] = useState<UserPoll | null>(null)
  const [isUpdateUserPollDialogOpen, setIsUpdateUserPollDialogOpen] = useState(false)
  const [userPollToUpdate, setUserPollToUpdate] = useState<UserPoll | null>(null)
  const [isUserPollDetailDialogOpen, setIsUserPollDetailDialogOpen] = useState(false)
  const [userPollSelected, setUserPollSelected] = useState<UserPoll | null>(null)
  const [isUserPollOrderDialogOpen, setIsUserPollOrderDialogOpen] = useState(false)

  const handleCreateUserPollDone = () => {
    refetchUserPolls()
    setIsCreateUserPollDialogOpen(false)
  }

  const handleDeleteUserPollDialogClose = () => {
    setIsDeleteUserPollDialogOpen(false)
    setUserPollToDelete(null)
  }

  const handleDeleteUserPollDone = () => {
    if (!userPollToDelete) return
    setUserPolls(userPolls.filter(userPoll => userPoll.id !== userPollToDelete.id))
    refetchUserPolls()
    handleDeleteUserPollDialogClose()
  }

  const handleUpdateUserPollDialogClose = () => {
    refetchUserPolls()
    setIsUpdateUserPollDialogOpen(false)
    setUserPollToUpdate(null)
  }

  const handleUserPollDetailDialogClose = () => {
    setIsUserPollDetailDialogOpen(false)
    refetchUserPolls()
    setUserPollSelected(null)
  }

  const handleUserPollOrderDialogClose = () => {
    setIsUserPollOrderDialogOpen(false)
  }

  const handleUpdateuserPollOrder = async (order: number[]) => {
    handleUserPollOrderDialogClose()
    await updateUserPollOrderApi(order)
    refetchUserPolls()
  }

  const handleUpdateUserPollDone = (updatedUserPoll: UserPoll) => {
    setUserPolls(
      userPolls.map(userPoll => {
        if (userPoll.id === updatedUserPoll.id) {
          return updatedUserPoll
        }
        return userPoll
      }),
    )
    handleUpdateUserPollDialogClose()
  }

  const actions = [
    {
      icon: 'add',
      tooltip: `Nuevo ${constants.TEST_NAME}`,
      isFreeAction: true,
      onClick: () => setIsCreateUserPollDialogOpen(true),
    },
    {
      icon: () => <FormatListNumberedIcon />,
      tooltip: `Ordenar ${constants.TEST_NAME}`,
      isFreeAction: true,
      onClick: () => setIsUserPollOrderDialogOpen(true),
    },
    {
      icon: () => <BarChartIcon />,
      tooltip: `Estadísticas`,
      onClick: (_: React.SyntheticEvent, row: UserPoll | UserPoll[]) => {
        setUserPollSelected(row as UserPoll)
        setStatisticsDialogOpen(true)
      },
    },
    {
      icon: () => <QuestionAnswerIcon />,
      tooltip: `Respuestas`,
      onClick: (_: React.SyntheticEvent, row: UserPoll | UserPoll[]) => {
        setUserPollSelected(row as UserPoll)
        setIsUserPollDetailDialogOpen(true)
      },
    },
    {
      icon: () => <EditIcon />,
      tooltip: `Editar ${constants.TEST_NAME}`,
      onClick: (_: React.SyntheticEvent, row: UserPoll | UserPoll[]) => {
        setUserPollToUpdate(row as UserPoll)
        setIsUpdateUserPollDialogOpen(true)
      },
    },
    {
      icon: 'delete',
      tooltip: `Eliminar`,
      onClick: (_: React.SyntheticEvent, row: UserPoll | UserPoll[]) => {
        setUserPollToDelete(row as UserPoll)
        setIsDeleteUserPollDialogOpen(true)
      },
    },
  ]

  return (
    <>
      <DefaultTable
        title={constants.TEST_NAME}
        columns={columns}
        data={userPolls}
        isLoading={isLoading}
        actions={actions}
      />
      {isCreateUserPollDialogOpen && (
        <CreateUserPollDialog
          handleClose={() => setIsCreateUserPollDialogOpen(false)}
          onDone={handleCreateUserPollDone}
        />
      )}
      {isDeleteUserPollDialogOpen && userPollToDelete && (
        <DeleteUserPollDialog
          handleClose={handleDeleteUserPollDialogClose}
          userPollId={userPollToDelete.id}
          userPollTitle={userPollToDelete.question}
          onDone={handleDeleteUserPollDone}
        />
      )}
      {isUpdateUserPollDialogOpen && userPollToUpdate && (
        <UpdateUserPollDialog
          handleClose={handleUpdateUserPollDialogClose}
          userPollToUpdate={userPollToUpdate}
          onDone={handleUpdateUserPollDone}
        />
      )}
      {isUserPollDetailDialogOpen && userPollSelected && (
        <UserPollDetailDialog
          handleClose={handleUserPollDetailDialogClose}
          userPoll={userPollSelected}
          tags={tags}
        />
      )}
      {isUserPollOrderDialogOpen && userPolls.length && (
        <UserPollOrderDialog
          handleClose={handleUserPollOrderDialogClose}
          userPolls={userPolls}
          onUpdateOrder={handleUpdateuserPollOrder}
        />
      )}
      {isStatisticsDialogOpen && userPollSelected && (
        <StatisticsUserPollDialog
          handleClose={() => setStatisticsDialogOpen(false)}
          userPoll={userPollSelected}
        />
      )}
    </>
  )
}

export { UserPolls }

import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, UploadButton, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'

import { UserPoll, UpdateUserPollResponse, UpdateUserPollData } from '../../types'
import { updateUserPoll } from '../../api'
import { useStyles } from './styles'
import { constants } from '../../constansts'

const rules = {
  question: [
    {
      validate: (value: string) => value.length <= 254,
      message: 'No puede superar los 254 caracteres',
    },
  ],
}

const requiredFields = ['question', 'expiryDate', 'active']

interface UpdateUserPollDialogProps {
  handleClose: () => void
  userPollToUpdate: UserPoll
  onDone: (newUserPoll: UserPoll) => void
}

const UpdateUserPollDialog: React.FC<UpdateUserPollDialogProps> = ({
  handleClose,
  onDone,
  userPollToUpdate,
}) => {
  const classes = useStyles()

  const [question, setQuestion] = useState(userPollToUpdate.question ?? '')
  const [active, setActive] = useState(userPollToUpdate.active)
  const [detail, setDetail] = useState(userPollToUpdate.detail)

  const [updateUserPollApi, isLoading] = useApiCall<UpdateUserPollData, UpdateUserPollResponse>(
    updateUserPoll,
  )

  const handlequestionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setQuestion(e.target.value)
  }

  const handleChangeActive: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active);
  }

  const fields = { question, active }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onUpdateUserPoll = async () => {
    const userPoll = {
      question,
      active,
    }
    const payload = {
      id: userPollToUpdate.id,
      payload: userPoll,
    }
    try {
      const { userPoll: updatedUserPoll } = await updateUserPollApi(payload)
      onDone(updatedUserPoll)
    } catch (err) {
      console.error(err)
      snackbar.show(`No se pudo editar ${constants.TEST_NAME}. Intente de nuevo.`)
    }
  }

  return (
    <Dialog
      title={`Editar ${constants.TEST_NAME}`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateUserPoll}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Pregunta"
            value={question}
            fullWidth
            onChange={handlequestionChange}
            disabled={isLoading}
            multiline
            error={errors.question.hasError}
            helperText={errors.question.message}
          />
        </Grid>
        <Grid item xs={11}>
          {detail.length > 1 && <FormControlLabel control={<Checkbox onChange={handleChangeActive} checked={active!== null && active} />} label="Activar" />}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateUserPollDialog }

import { AxiosRequestConfig } from 'axios'

const getUsers = (): AxiosRequestConfig => ({
  url: '/users?statistics=true',
  method: 'GET',
})

const getUserPollResult = (userId: number): AxiosRequestConfig => ({
  url: `/users/${userId}/poll-result`,
  method: 'GET',
})


export { getUsers, getUserPollResult }

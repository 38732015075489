import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  iconUploadContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  iconUploadPreviewContainer: {
    flex: 1,
    textAlign: 'center',
    border: '1px dashed #ccc',
    margin: '0 20px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconPreview: { maxWidth: 30, maxHeight: '100%' },
  imageUploadContainer: {
    height: '250px',
    display: 'flex',
    alignItems: 'center',
  },
  imageUploadPreviewContainer: {
    height: '100%',
    flex: 1,
    margin: '10px',
    border: '1px dashed #ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePreview: { maxWidth: 300, maxHeight: 'calc(100% - 20px)' },
})

export { useStyles }

import React, { useState, useEffect } from 'react'
import { Grid, TextField, FormControl, InputLabel } from '@material-ui/core'
import { Moment } from 'moment'
import { DatePicker } from '@material-ui/pickers'
import { MultiSelect } from 'react-multi-select-component'
import Divider from '@material-ui/core/Divider'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { Dialog, UploadButton, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { STRINGS } from 'consts'
import { Tag } from 'features/Main/Tags/types'
import { resizeFile } from 'utils'

import { Coupon, CreateCouponPayload, CreateCouponResponse } from '../../types'
import { createCoupon } from '../../api'
import { useStyles } from './styles'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  accentDescription: [
    {
      validate: (value: string) => value.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  description: [
    {
      validate: (value: string) => !value || value.length <= 254,
      message: 'No puede superar los 254 caracteres',
    },
  ],
  additionalText: [
    {
      validate: (value: string) => (value && value.length <= 100) || !value.length,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  image: [],
  icon: [],
  expiryDate: [],
  order: [
    {
      validate: (value: string) => (value && Number(value) > 0) || !value,
      message: 'El orden debe ser mayor a 0',
    },
  ],
}

const requiredFields = [
  'title',
  'accentDescription',
  'image',
  'icon',
  'expiryDate',
  'additionalText',
]

interface CreateCouponDialogProps {
  handleClose: () => void
  onDone: (newCoupon: Coupon) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const CreateCouponDialog: React.FC<CreateCouponDialogProps> = ({
  handleClose,
  onDone,
  tags,
  calcUsersByTags,
}) => {
  const classes = useStyles()

  const [title, setTitle] = useState('')
  const [accentDescription, setAccentDescription] = useState('')
  const [additionalText, setAdditionalText] = useState('')
  const [description, setDescription] = useState('')
  const [image, setImage] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState('')
  const [icon, setIcon] = useState<File | null>(null)
  const [iconPreview, setIconPreview] = useState('')
  const [expiryDate, setExpiryDate] = useState<Moment | null>(null)
  const [order, setOrder] = useState('')
  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [usersByTags, setUsersByTags] = useState(0)
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(false)

  useEffect(() => {
    const getUsersByTags = async () => {
      return await calcUsersByTags(tags, false)
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
  }, [tags])

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const [createCouponApi, isLoading] = useApiCall<CreateCouponPayload, CreateCouponResponse>(
    createCoupon,
  )

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
  }

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setDescription(e.target.value)
  }

  const handleAdditionalTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAdditionalText(e.target.value)
  }
  const handleAccentDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setAccentDescription(e.target.value)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setExpiryDate(newDate)
  }

  const handleImageChange = async (file: File) => {
    setImagePreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 600, 600)
    setImage(image)
  }

  const handleIconChange = async (file: File) => {
    setIconPreview(URL.createObjectURL(file))
    const image = await resizeFile(file, 192, 192)
    setIcon(image)
  }

  const handleOrderChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target
    if (/^\d*$/.test(value)) {
      setOrder(value)
    }
  }
  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const fields = {
    title,
    accentDescription,
    description,
    image,
    icon,
    expiryDate,
    order,
    additionalText,
    tagsSelected,
  }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules, requiredFields)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onCreateCoupon = async () => {
    if (!image || !icon || !expiryDate) return
    const coupon = {
      title,
      accentDescription,
      description,
      image,
      icon,
      expiryDate: expiryDate.toISOString(),
      order,
      additionalText,
      tagsSelected,
      allTagsAreRequired,
    }
    try {
      const { coupon: newCoupon } = await createCouponApi(coupon)
      onDone(newCoupon)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo crear cupón. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Nueva promo"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onCreateCoupon}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Título"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción corta"
            value={accentDescription}
            fullWidth
            onChange={handleAccentDescriptionChange}
            disabled={isLoading}
            required
            error={errors.accentDescription.hasError}
            helperText={errors.accentDescription.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Descripción larga"
            value={description}
            fullWidth
            onChange={handleDescriptionChange}
            disabled={isLoading}
            multiline
            error={errors.description.hasError}
            helperText={errors.description.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Texto adicional"
            value={additionalText}
            fullWidth
            onChange={handleAdditionalTextChange}
            disabled={isLoading}
            required
            error={errors.additionalText.hasError}
            helperText={errors.additionalText.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            value={expiryDate}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            style={{ width: '100%' }}
            label="Vencimiento"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            value={order}
            onChange={handleOrderChange}
            label="Order"
            type="tel"
            fullWidth
            error={errors.order.hasError}
            helperText={errors.order.message}
            InputProps={{
              inputProps: { min: 1 },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.multiselectTitle}>Tag</InputLabel>
          <FormControl className={classes.multiselect}>
            <MultiSelect
              options={tagsForSelect}
              value={tagsSelected}
              onChange={handleSetTags}
              labelledBy="Seleccionar Tags"
              hasSelectAll={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
            }
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.iconUploadContainer}>
            <UploadButton
              id="coupon-icon-upload-button"
              accept=".jpg, .jpeg, .png"
              label="Subir ícono"
              onChange={handleIconChange}
            />
            <div className={classes.iconUploadPreviewContainer}>
              <img src={iconPreview} alt="" className={classes.iconPreview} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.imageUploadContainer}>
            <UploadButton
              id="coupon-image-upload-button"
              accept=".jpg, .jpeg, .png"
              label="Subir imagen"
              onChange={handleImageChange}
            />
            <div className={classes.imageUploadPreviewContainer}>
              <img src={imagePreview} alt="" className={classes.imagePreview} />
            </div>
          </div>
        </Grid>
        <Grid item xs={11}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={11}>
          <InputLabel>Esta acción impactará a {usersByTags} usuarios</InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateCouponDialog }

import React from 'react'

import { COLORS } from 'styles'

interface Props {
  href: string
}

const ExternalLink: React.FC<Props> = ({ href, children }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    style={{
      color: COLORS.ACCENT,
    }}
  >
    {children}
  </a>
)

export { ExternalLink }

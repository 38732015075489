import { AxiosRequestConfig } from 'axios'

import { DataParams } from './types'

const downloadData = ({
  storeId,
  startDate,
  endDate,
  month
}: DataParams): AxiosRequestConfig => {
  const params = {
    ...(startDate ? { startDate } : {}),
    ...(endDate ? { endDate } : {}),
    ...(month ? { month } : {}),
    ...(storeId ? { storeId } : {})
  }

  return {
    url: 'dashboard/cards',
    method: 'GET',
    params,
  }
}

export { downloadData }
import get from 'lodash/get'
import moment from 'moment'

import { CouponUser, PrizeUser, User } from 'features/Main/Users/types'

import { UserVisit, UserTest } from '../types'
import { STRINGS } from 'consts'

const DATE_FORMAT = 'DD/MM/YYYY'
const DATE_HOUR_FORMAT = 'DD/MM/YYYY HH:MM'

const formatDate = (date?: Date, format?: string) =>
  date ? moment(date).format(format ?? DATE_HOUR_FORMAT) : ''

export const downloadCSV = (content: string, dataType: string) => {
  const hiddenElement = document.createElement('a')
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(content)}`
  hiddenElement.target = '_blank'
  hiddenElement.download = `${dataType}-list.csv`
  hiddenElement.click()
}

export const formatCSV = (users: User[]) => {
  const replacer = (_: string, value: string) => value ?? ''
  const headers = Object.keys(users[0]) as (keyof User)[]

  const parsedUsers = users
    .map(user => ({
      ...user,
      birthdate: formatDate(user.birthdate, DATE_FORMAT),
    }))
    .map(user =>
      headers.map((fieldName: keyof User) => JSON.stringify(user[fieldName], replacer)).join(','),
    )

  const csv = [headers.join(','), ...parsedUsers].join('\r')

  return csv
}

export const formatUserCSV = (users: UserVisit[]) => {
  const replacer = (value: string) => value ?? ''
  const titles = [
    { name: 'user.id', title: 'Id Usuario' },
    { name: 'user.email', title: 'Mail Usuario' },
    { name: 'user.fullname', title: 'Nombre Usuario' },
    { name: 'firstLogin', title: 'Primer Login' },
    { name: 'visitedAt', title: 'Visitado' },
    { name: 'admin.email', title: 'Mail Empleado' },
    { name: 'store.name', title: 'Tienda' },
    { name: 'level', title: 'Nivel' },
  ]

  const header = titles.map(row => row.title)

  const parsedUsers = users.filter(user => user.user)
    .map(user => ({
      ...user,
      firstLogin: formatDate(user.user.firstLogin, STRINGS.DATE_TIME_FORMAT),
      visitedAt: formatDate(user.visitedAt, STRINGS.DATE_TIME_FORMAT),
    }))
    .map(user => titles.map(({ name }) => JSON.stringify(replacer(get(user, name, '')))).join(','))

  const csv = [header.join(','), ...parsedUsers].join('\r')

  return csv
}

export const formatCouponCSV = (coupons: CouponUser[]) => {
  const replacer = (value: string) => value ?? ''

  const titles = [
    { name: 'id', title: 'Id Cupon Usuario' },
    { name: 'user.fullname', title: 'Usuario' },
    { name: 'user.email', title: 'Email' },
    { name: 'coupon.title', title: 'Cupón' },
    { name: 'coupon.expiryDate', title: 'Fecha de Expiración' },
    { name: 'redeemedAt', title: 'Fecha de Cambio' },
    { name: 'admin.email', title: 'Email Admin' },
    { name: 'store.name', title: 'Tienda' },
  ]

  const header = titles.map(row => row.title)

  const parsedRows = coupons
    .map(coupon => ({
      ...coupon,
      coupon: { expiryDate: formatDate(coupon.coupon.expiryDate, STRINGS.DATE_TIME_FORMAT), title: coupon.coupon.title },
      redeemedAt: formatDate(coupon.redeemedAt, STRINGS.DATE_TIME_FORMAT),
    }))
    .map(coupon =>
      titles.map(({ name }) => JSON.stringify(replacer(get(coupon, name, '')))).join(',')
    )

  const csv = [header.join(','), ...parsedRows].join('\r')

  return csv
}

export const formatPrizesCSV = (prizes: PrizeUser[]) => {
  const replacer = (value: string) => value ?? ''

  const titles = [
    { name: 'id', title: 'Id Premio Usuario' },
    { name: 'user.fullname', title: 'Usuario' },
    { name: 'user.email', title: 'Email' },
    { name: 'prize.title', title: 'Premio' },
    { name: 'createdAt', title: 'Fecha de Creación' },
    { name: 'redeemedAt', title: 'Fecha de Cambio' },
    { name: 'adminEmail', title: 'Email Admin' },
    { name: 'storeName', title: 'Tienda' },
  ]

  const header = titles.map(row => row.title)

  const parsedRows = prizes
    .map(prize => ({
      ...prize,
      adminEmail: prize.admin?.email || '',
      storeName: prize.store?.name || '',
      createdAt: formatDate(prize.createdAt, STRINGS.DATE_TIME_FORMAT),
      redeemedAt: formatDate(prize.redeemedAt, STRINGS.DATE_TIME_FORMAT),
    }))
    .map(prize =>
      titles.map(({ name }) => JSON.stringify(replacer(get(prize, name, '')))).join(','),
    )

  const csv = [header.join(','), ...parsedRows].join('\r')

  return csv
}

export const formatUserTestCSV = (tests: UserTest[]) => {
  const replacer = (value: string) => value ?? ''
  const titles = [
    { name: 'user.email', title: 'Mail Usuario' },
    { name: 'question', title: 'Pregunta' },
    { name: 'answer', title: 'Respuesta' },
    { name: 'tag', title: 'Tag' },
    { name: 'date', title: 'fecha' }
  ]

  const header = titles.map(row => row.title)
  const parsedTests = tests
    .map(test => ({
      ...test,
      email: test.user.email,
      question: test.userPoll?.question || '',
      answer: test.userPollDetail.answer,
      tag: test.userPollDetail?.userPollDetailTag?.title||'',
      date: formatDate(test.updatedAt, STRINGS.DATE_TIME_FORMAT),
    }))
    .map(test =>
      titles.map(({ name }) => JSON.stringify(replacer(get(test, name, '')))).join(','),
    )
    const csv = [header.join(','), ...parsedTests].join('\r')
    return csv

}

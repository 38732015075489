import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { Dialog } from 'components'
import { useApiCall } from 'hooks'

import { DrawerItem, UpdateDrawerItemSelectionPayload } from '../types'
import { updateDrawerItemsSelection } from '../api'

interface Props {
  handleClose: () => void
  drawerItems: DrawerItem[]
  onDone: (selectedIds: number[]) => void
}

const SelectDrawerItemsDialog: React.FC<Props> = ({ handleClose, drawerItems, onDone }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>(
    drawerItems.filter(item => item.selected).map(item => item.id),
  )
  const [updateSelectionApi, isLoading] = useApiCall<UpdateDrawerItemSelectionPayload, void>(
    updateDrawerItemsSelection,
  )

  const onSelectItem = (newId: number) => {
    if (selectedIds.includes(newId)) {
      const newSelectedIds = selectedIds.filter(id => id !== newId)
      setSelectedIds(newSelectedIds)
      return
    }
    setSelectedIds(selectedIds.concat(newId))
  }

  const onSaveSelection = async () => {
    try {
      await updateSelectionApi({ selected: selectedIds })
      onDone(selectedIds)
    } catch (err) {
      // TODO: handle properly
      console.error(err)
    }
  }

  const isSubmitDisabled = selectedIds.length > 2

  return (
    <>
      <Dialog
        title="Seleccionar links para la pantalla de inicio"
        isOpen
        showActions
        onCancel={handleClose}
        isLoading={isLoading}
        okButtonText="Guardar selección"
        okButtonProps={{ disabled: isSubmitDisabled }}
        onAccept={onSaveSelection}
      >
        <p>Máximo permitido: 2</p>
        <List dense>
          {drawerItems.map(drawerItem => (
            <ListItem
              key={drawerItem.id}
              button
              selected={selectedIds.includes(drawerItem.id)}
              onClick={() => onSelectItem(drawerItem.id)}
              style={{ marginBottom: 5 }}
            >
              <ListItemText primary={drawerItem.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  )
}

export { SelectDrawerItemsDialog }

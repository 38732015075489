import React from 'react'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { deleteUserPoll } from '../api'

interface DeleteUserPollDialogProps {
  handleClose: () => void
  onDone: () => void
  userPollId: number
  userPollTitle: string
}

const DeleteUserPollDialog: React.FC<DeleteUserPollDialogProps> = ({
  handleClose,
  userPollId,
  userPollTitle,
  onDone,
}) => {
  const [deleteUserPollApi, isLoading] = useApiCall<number, void>(deleteUserPoll)

  const onDeleteUserPoll = async () => {
    try {
      await deleteUserPollApi(userPollId)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo eliminar cupón. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar el cupón "${userPollTitle}"?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onDeleteUserPoll}
    />
  )
}

export { DeleteUserPollDialog }

import Resizer from "react-image-file-resizer";

const resizeFile = (file: File, width: number, height: number): Promise<File> =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            100,
            0,
            // @ts-ignore
            (uri) => {
                // @ts-ignore
                resolve(uri);
            },
            "file"
        )
    });

const convertToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};


export { resizeFile, convertToBase64 }
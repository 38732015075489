import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

import { Dialog, GooglePlacesAutocomplete, snackbar } from 'components'
import { Place } from 'components/GooglePlacesAutocomplete/types'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS } from 'consts'

import { createStore } from '../api'
import { CreateStorePayload, CreateStoreResponse, Store } from '../types'

const rules = {
  address: [],
  name: [
    {
      validate: (name: string) => name.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  location: [],
}

interface CreateStoresDialogProps {
  handleClose: () => void
  onDone: (newStore: Store) => void
}

const CreateStoresDialog: React.FC<CreateStoresDialogProps> = ({ handleClose, onDone }) => {
  const [address, setAddress] = useState('')
  const [name, setName] = useState('')
  const [location, setLocation] = useState<[number, number]>([0, 0])

  const data = { address, name, location }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const [createStoreApi, isLoading] = useApiCall<CreateStorePayload, CreateStoreResponse>(
    createStore,
  )

  const onSelectedPlace = (place: Place) => {
    setAddress(place.address)
    setLocation([place.lat, place.lng])
  }

  const handleSubmit = async () => {
    try {
      const { store: newStore } = await createStoreApi(data)
      onDone(newStore)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title="Nuevo local"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Crear local"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre"
            required
            onChange={e => setName(e.target.value)}
            value={name}
            error={errors.name.hasError}
            helperText={errors.name.message}
          />
        </Grid>
        <Grid item xs={12}>
          <GooglePlacesAutocomplete onSelect={onSelectedPlace} required />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { CreateStoresDialog }

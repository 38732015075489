import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        background: "#fff"
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary
      },
      title: {
        textAlign: "left",
        padding: theme.spacing(4),
        marginLeft: "2rem",
        marginTop: "1.5rem",
        color: theme.palette.text.secondary,
        fontSize: "1.5em",
    
      },
      input: {
        width: "100%"
      },
      rewardType: {
        textAlign: "left",
        height: '10%',
        alignItems: 'center',
      },
      checkbox: {
        textAlign: "right",
      },
      iconSection: {
        paddingLeft: "2rem",
        textAlign: "left",
      },
      iconUploadContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      iconUploadPreviewContainer: {
        textAlign: 'center',
        border: '1px dashed #ccc',
        margin: '0 20px',
        height: '50px',
        width: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      iconPreview: { maxWidth: 30, maxHeight: '100%' },
})
)

export { useStyles }

import { AxiosRequestConfig } from 'axios'

import { CreateTagPayload, GetTotalUsersWithAllTagsPayload, UpdateTagData } from './types'

const getTags = (): AxiosRequestConfig => ({
  url: '/tags',
  method: 'GET',
})

const getActiveTags = ():AxiosRequestConfig => ({
  url: '/tags/active',
  method: 'GET',
})

const createTag = (tag: CreateTagPayload): AxiosRequestConfig => {
  const payload = {} as CreateTagPayload
	const { title, description, active } = tag
  
	payload.title=title
	payload.description=description
	payload.active=active

  return {
    url: '/tags',
    method: 'POST',
    data: payload,
  }
}

const updateTag = (data: UpdateTagData): AxiosRequestConfig => {
	const payload = new FormData()
	const { title, description, active } = data.payload
	if (title) payload.append('title', title)
	if (description) payload.append('description', description)
  if (active !== undefined) payload.append('active', active.toString())
	
  return {
    url: `/tags/${data.id}`,
    method: 'PUT',
    data: payload,
  }
}

const deleteTag = (id: number): AxiosRequestConfig => ({
  url: `/tags/${id}`,
  method: 'DELETE',
})

const getTotalUsersWithAllTags = (data: GetTotalUsersWithAllTagsPayload): AxiosRequestConfig => {
  return {
    url: `/tags/total-users`,
    method: 'POST',
    data: {tags : data.tagIds.toString()}
  }
}

export { getTags, createTag, updateTag, deleteTag, getActiveTags, getTotalUsersWithAllTags }

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Dialog, UploadButton, snackbar } from 'components'
import { useApiCall, useCheckFormErrors } from 'hooks'

import { Tag, UpdateTagResponse, UpdateTagData } from '../../types'
import { updateTag } from '../../api'
import { useStyles } from './styles'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 100,
      message: 'No puede superar los 100 caracteres',
    },
  ],
  description: [
    {
      validate: (description: string) => description.length <= 200,
      message: 'No puede superar los 100 caracteres',
    },
  ]
}

interface UpdateTagDialogProps {
  handleClose: () => void
  tagToUpdate: Tag
  onDone: (newPrize: Tag) => void
}

const UpdateTagDialog: React.FC<UpdateTagDialogProps> = ({
  handleClose,
  onDone,
  tagToUpdate,
}) => {
  const classes = useStyles()

  const [title, setTitle] = useState(tagToUpdate.title)
  const [description, setDescription] = useState(tagToUpdate.description)
  const [active, setActive] = useState(tagToUpdate.active)

  const [nameFile, setNameFile] = useState('')

  const [updateTagApi, isLoading] = useApiCall<UpdateTagData, UpdateTagResponse>(updateTag)

  const handleTitleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setTitle(e.target.value)
  }

  const handleActiveChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setActive(!active)
  }

  const handleDescriptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setDescription(e.target.value)
  }

  const fields = { title, description }
  const { hasErrors, isAnyFieldEmpty, errors } = useCheckFormErrors(fields, rules)
  const isSubmitDisabled = hasErrors || isAnyFieldEmpty || isLoading

  const onUpdateTag = async () => {
    const tag = {
      title,
      description,
      active,
    }
    const payload = {
      id: tagToUpdate.id,
      payload: tag,
    }
    try {
      const { tag: updatedTag } = await updateTagApi(payload)
      onDone(updatedTag)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo editar la etiqueta(tag). Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title="Editar etiqueta(tag)"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateTag}
    >
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <TextField
            label="Título"
            value={title}
            fullWidth
            onChange={handleTitleChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
						inputProps={{ maxLength: 25 }}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            label="Descripción"
            value={description}
            fullWidth
            onChange={handleDescriptionChange}
            disabled={isLoading}
            required
            error={errors.title.hasError}
            helperText={errors.title.message}
						inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
					<FormControlLabel
            control={<Checkbox onChange={handleActiveChange}
              checked={active}
            />}
            label="Activo"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateTagDialog }

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  iconUploadContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }
})

export { useStyles }

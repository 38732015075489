export enum PollKeys {
  FoodPreference = 'foodPreference',
  FavoriteSnack = 'favoriteSnack',
  FavoriteInfusion = 'favoriteInfusion',
  FavoriteSport = 'favoriteSport',
}
export type UserPollResults = {
  [key in PollKeys]: string
} & {
  id: number
  user_id: number
}

import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'styles'

const useStyles = makeStyles(theme => ({
  suggestionsContainer: {
    position: 'absolute',
    zIndex: 1,
    background: COLORS.WHITE,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: theme.shadows[3],
  },
  suggestion: {
    padding: '10px 5px',
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.LIGHT_GREY,
    },
  },
}))

export { useStyles }

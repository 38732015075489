import React from 'react'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { processManualReward } from '../api'
import { ManualReward, UpdateManualRewardResponse } from '../types'

interface ProcessManualRewardDialogProps {
  handleClose: () => void
  onDone: (newManualReward: ManualReward) => void
  manualRewardId: number
  manualRewardTitle: string
}

const ProcessManualRewardDialog: React.FC<ProcessManualRewardDialogProps> = ({
  handleClose,
  manualRewardId,
  manualRewardTitle,
  onDone,
}) => {
  const [processManualRewardApi, isLoading] = useApiCall<number, UpdateManualRewardResponse>(processManualReward)

  const onProcessManualReward = async () => {
    try {
      const { manualReward: updatedManualReward } = await processManualRewardApi(manualRewardId)
      onDone(updatedManualReward)
    } catch (err) {
      snackbar.show('No se pudo procesar el Reward Manual. Contacte a su administrador')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea procesar el Reward Manual "${manualRewardTitle}"?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Procesar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onProcessManualReward}
    />
  )
}

export { ProcessManualRewardDialog }

import React from 'react'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'

import { deleteManualReward } from '../api'

interface DeletePrizeDialogProps {
  handleClose: () => void
  onDone: () => void
  manualRewardId: number
  manualRewardTitle: string
}

const DeleteManualRewardDialog: React.FC<DeletePrizeDialogProps> = ({
  handleClose,
  manualRewardId,
  manualRewardTitle,
  onDone,
}) => {
  const [deleteManualRewardApi, isLoading] = useApiCall<number, void>(deleteManualReward)

  const onDeletePrize = async () => {
    try {
      await deleteManualRewardApi(manualRewardId)
      onDone()
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo eliminar la el Reward Manual. Intente de nuevo.')
    }
  }

  return (
    <Dialog
      title={`¿Está seguro que desea eliminar el Reward Manual "${manualRewardTitle}"?`}
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Eliminar"
      okButtonProps={{ disabled: isLoading }}
      onAccept={onDeletePrize}
    />
  )
}

export { DeleteManualRewardDialog }

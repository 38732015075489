import React, { useState } from 'react'
import { Grid, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core'

import { Dialog, snackbar } from 'components'
import { useApiCall } from 'hooks'
import { ERRORS } from 'consts'

import { Admin, Role, UpdateAdminRolesData } from '../../types'
import { updateAdminRoles } from '../../api'
import { roleLabelsMap } from '../../constants'
import { useStyles } from './styles'

interface Props {
  admin: Admin
  handleClose: () => void
  onDone: () => void
  roles: Role[]
}

const UpdateAdminDialog: React.FC<Props> = ({ admin, handleClose, onDone, roles }) => {
  const classes = useStyles()

  const [updateAdminApi, isUpdateAdminLoading] = useApiCall<UpdateAdminRolesData, void>(
    updateAdminRoles,
  )

  const [selectedRoles, setSelectedRoles] = useState<number[]>(admin.roles.map(role => role.id))

  const handleRoleSelection = (id: number): React.ChangeEventHandler<HTMLInputElement> => e => {
    setSelectedRoles(
      e.target.checked ? selectedRoles.concat(id) : selectedRoles.filter(val => val !== id),
    )
  }

  const isUntouched =
    selectedRoles.every(role => admin.roles.map(r => r.id).includes(role)) &&
    selectedRoles.length === admin.roles.length
  const atLeastOneRoleSelected = selectedRoles.length > 0

  const isSubmitDisabled = isUpdateAdminLoading || isUntouched || !atLeastOneRoleSelected

  const onUpdateAdmin = async () => {
    try {
      await updateAdminApi({
        id: admin.id,
        payload: selectedRoles,
      })
      onDone()
    } catch (err) {
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  return (
    <Dialog
      title="Editar administrador"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isUpdateAdminLoading}
      okButtonText="Editar"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={onUpdateAdmin}
      contentClassName={classes.dialogContent}
      className={classes.dialogContainer}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormGroup row>
            {roles.map(role => (
              <FormControlLabel
                key={role.id}
                control={
                  <Checkbox
                    defaultChecked={selectedRoles.includes(role.id)}
                    onChange={handleRoleSelection(role.id)}
                  />
                }
                label={roleLabelsMap[role.name].label}
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { UpdateAdminDialog }

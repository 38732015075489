import { AxiosRequestConfig } from 'axios'

import { CreateNotificationPayload, UpdateNotificationData } from './types'

const getNotifications = (): AxiosRequestConfig => ({
  url: '/notifications',
  method: 'GET',
})

const createNotification = (payload: CreateNotificationPayload): AxiosRequestConfig => ({
  url: '/notifications',
  method: 'POST',
  data: { notification: payload },
})

const updateNotification = (data: UpdateNotificationData): AxiosRequestConfig => ({
  url: `/notifications/${data.id}`,
  method: 'PUT',
  data: { notification: data.payload },
})

const deleteNotification = (id: number): AxiosRequestConfig => ({
  url: `/notifications/${id}`,
  method: 'DELETE',
})

export { getNotifications, createNotification, updateNotification, deleteNotification }

import React, { useState, useEffect } from 'react'
import { Grid, TextField } from '@material-ui/core'

import { PollKeys } from 'types'
import { Dialog } from 'components'
import { getUserPollResult } from './../../api'
import { User, UserPollResult } from '../../types'
import { UserPoll } from 'features/Main/UserPoll/types'
import { useApiCall } from 'hooks'


interface PollDialogProps {
  data: User
  onCancel: () => void
}

const PollDialog = ({ ...props }: PollDialogProps) => {  
  const {onCancel, data} = props

  const [pollResults, setPollResults] = useState<UserPollResult[]|undefined>(undefined)
  const [getUserPollResultApi, isLoading] = useApiCall<number, UserPollResult[]>(
    getUserPollResult,
  )

  useEffect(() => {
    if (!pollResults) {
      const fetchData = async () => {
        const userPollResult = await getUserPollResultApi(data.id)
        if (userPollResult) setPollResults(userPollResult)
      }
      fetchData()
    }    
  }, [])

  return (
    <Dialog title="Green Test" isOpen showActions readOnly onCancel={onCancel}>
      <Grid container spacing={3}>
        {!pollResults || !pollResults.length ? 'No hay datos' :
        pollResults.map((item) => (
          <Grid key={item.id} item xs={12}>
            {item.userPoll?.question}
            <TextField label={item.userPollDetail.answer} fullWidth disabled/>
          </Grid>
        ))
        }        
      </Grid>
    </Dialog>
  )
}

export { PollDialog }

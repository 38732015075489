import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

import { Dialog } from 'components'
import { useApiCall } from 'hooks'

import { DrawerItem, UpdateDrawerItemOrderPayload } from '../types'
import { updateDrawerItemsOrder } from '../api'

enum PositionChangesEnum {
  Upwards = 'Upwards',
  Downwards = 'Downwards',
}

interface Props {
  drawerItems: DrawerItem[]
  handleClose: () => void
  onDone: (newData: DrawerItem[]) => void
}

const OrderDrawerItemsDialog: React.FC<Props> = ({ drawerItems, handleClose, onDone }) => {
  const [dataOrder, setDataOrder] = useState<DrawerItem[]>(drawerItems)
  const [updateOrderApi, isLoading] = useApiCall<UpdateDrawerItemOrderPayload[], void>(
    updateDrawerItemsOrder,
  )

  const handleChangePosition = (direction: PositionChangesEnum) => async (prevPosition: number) => {
    const newDataOrder = [...dataOrder]
    if (direction === PositionChangesEnum.Upwards) {
      newDataOrder[prevPosition - 1] = {
        ...dataOrder[prevPosition - 2],
        position: prevPosition,
      }
      newDataOrder[prevPosition - 2] = {
        ...dataOrder[prevPosition - 1],
        position: prevPosition - 1,
      }
    } else {
      newDataOrder[prevPosition - 1] = {
        ...dataOrder[prevPosition],
        position: prevPosition,
      }
      newDataOrder[prevPosition] = {
        ...dataOrder[prevPosition - 1],
        position: prevPosition + 1,
      }
    }
    setDataOrder(newDataOrder)
  }

  const handleRaisePosition = handleChangePosition(PositionChangesEnum.Upwards)

  const handleDropPosition = handleChangePosition(PositionChangesEnum.Downwards)

  const onSave = async () => {
    try {
      await updateOrderApi(dataOrder.map(item => ({ id: item.id, position: item.position })))
      onDone(dataOrder)
    } catch (err) {
      // TODO: handle properly
      console.error(err)
    }
  }

  return (
    <Dialog
      title="Ordenar links"
      isOpen
      showActions
      onCancel={handleClose}
      isLoading={isLoading}
      okButtonText="Guardar orden"
      onAccept={onSave}
      contentStyle={{ minWidth: 500 }}
    >
      {dataOrder.map(item => (
        <List key={item.id} dense>
          <ListItem>
            <ListItemText primary={item.name} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                disabled={item.position === 1}
                onClick={() => handleRaisePosition(item.position)}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                edge="end"
                disabled={item.position === dataOrder.length}
                onClick={() => handleDropPosition(item.position)}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
    </Dialog>
  )
}

export { OrderDrawerItemsDialog }

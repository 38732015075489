import React, { useState } from 'react'

import { snackbar } from 'components'
import { useApi } from 'hooks'
import { ERRORS } from 'consts'

import { DrawerItem, GetDrawerItemsResponse } from './types'
import { getDrawerItems } from './api'
import {
  CreateDrawerItemDialog,
  UpdateDrawerItemDialog,
  SelectDrawerItemsDialog,
  OrderDrawerItemsDialog,
  DeleteDrawerItemDialog,
  DrawerItemsTable,
} from './components'

const sortItemsByPosition = (drawerItems: DrawerItem[]) =>
  drawerItems.sort((a, b) => (a.position > b.position ? 1 : -1))

const getDrawerItemsResponseGetter = (data: GetDrawerItemsResponse) =>
  data?.drawerItems ? sortItemsByPosition(data.drawerItems) : []

const DrawerItems: React.FC = () => {
  const [{ data: drawerItems, isLoading: isGetDrawerItemsLoading }, fetchDrawerItems] = useApi<
    GetDrawerItemsResponse,
    DrawerItem[]
  >(getDrawerItems, getDrawerItemsResponseGetter, {
    baseData: [],
    onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
  })

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [updateDialogData, setUpdateDialogData] = useState<DrawerItem | null>(null)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [drawerItemToDelete, setDrawerItemToDelete] = useState<null | DrawerItem>(null)
  const [isSelectionDialogOpen, setIsSelectionDialogOpen] = useState(false)
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false)

  const handleCreate = () => {
    setIsCreateDialogOpen(true)
  }
  const handleCloseCreate = () => {
    setIsCreateDialogOpen(false)
  }

  const handleCreated = async () => {
    fetchDrawerItems()
    handleCloseCreate()
  }

  const handleUpdate = (_: React.SyntheticEvent, row: DrawerItem | DrawerItem[]) => {
    setUpdateDialogData(row as DrawerItem)
    setIsUpdateDialogOpen(true)
  }

  const handleCloseUpdate = () => {
    setIsUpdateDialogOpen(false)
    setUpdateDialogData(null)
  }

  const handleUpdated = async () => {
    fetchDrawerItems()
    handleCloseUpdate()
  }

  const handleEditSelection = () => {
    setIsSelectionDialogOpen(true)
  }
  const handleCloseEditSelection = () => {
    setIsSelectionDialogOpen(false)
  }

  const handleSelectionUpdated = () => {
    fetchDrawerItems()
    handleCloseEditSelection()
  }

  const handleDelete = (_: React.SyntheticEvent, row: DrawerItem | DrawerItem[]) => {
    setDrawerItemToDelete(row as DrawerItem)
    setIsDeleteDialogOpen(true)
  }
  const handleCloseDelete = () => {
    setIsDeleteDialogOpen(false)
    setDrawerItemToDelete(null)
  }
  const handleDeleted = async () => {
    handleCloseDelete()
    if (!drawerItemToDelete) return
    fetchDrawerItems()
  }

  const handleEditOrder = () => {
    setIsOrderDialogOpen(true)
  }
  const handleCloseEditOrder = () => {
    setIsOrderDialogOpen(false)
  }
  const handleOrderUpdated = () => {
    fetchDrawerItems()
    handleCloseEditOrder()
  }

  return (
    <>
      <DrawerItemsTable
        isLoading={isGetDrawerItemsLoading}
        data={drawerItems}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
        handleEditSelected={handleEditSelection}
        handleEditOrder={handleEditOrder}
      />
      {isCreateDialogOpen && (
        <CreateDrawerItemDialog handleClose={handleCloseCreate} onDone={handleCreated} />
      )}
      {isUpdateDialogOpen && updateDialogData && (
        <UpdateDrawerItemDialog
          handleClose={handleCloseUpdate}
          onDone={handleUpdated}
          drawerItem={updateDialogData}
        />
      )}
      <DeleteDrawerItemDialog
        isOpen={isDeleteDialogOpen}
        handleClose={handleCloseDelete}
        item={drawerItemToDelete}
        onDone={handleDeleted}
      />
      {isSelectionDialogOpen && (
        <SelectDrawerItemsDialog
          handleClose={handleCloseEditSelection}
          drawerItems={drawerItems}
          onDone={handleSelectionUpdated}
        />
      )}
      {isOrderDialogOpen && (
        <OrderDrawerItemsDialog
          handleClose={handleCloseEditOrder}
          drawerItems={drawerItems}
          onDone={handleOrderUpdated}
        />
      )}
    </>
  )
}

export { DrawerItems }

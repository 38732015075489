const calculateUserTestByTags = (userTestTagData: any[], tags: any[]) => {
  let sum = 0;

  if (!tags.length) {
    userTestTagData.forEach(element => {
      sum += element.count;
    });
    return sum;
  }

  userTestTagData.forEach(element => {
    sum += (tags.find(tag => (tag.id == element.tagId || tag.value == element.tagId))) ? element.count : 0;
  });
  return sum;
}


export default calculateUserTestByTags
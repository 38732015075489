import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  overflow: {
    overflow: 'visible',
  },
  dialogContent: {
    padding: 24,
  },
}))

export { useStyles }

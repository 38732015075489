import { AxiosRequestConfig } from 'axios'

import { CreateAdminPayload, UpdateAdminRolesData } from './types'

const getAdmins = (): AxiosRequestConfig => ({
  url: '/admins',
  method: 'GET',
})

const createAdmin = (adminPayload: CreateAdminPayload): AxiosRequestConfig => ({
  url: '/admins',
  method: 'POST',
  data: { admin: adminPayload },
})

const updateAdminRoles = (data: UpdateAdminRolesData): AxiosRequestConfig => ({
  url: `/admins/${data.id}/roles`,
  method: 'PUT',
  data: { roles: data.payload },
})

const deleteAdmin = (id: number): AxiosRequestConfig => ({
  url: `/admins/${id}`,
  method: 'DELETE',
})

const getRoles = (): AxiosRequestConfig => ({
  url: '/roles',
  method: 'GET',
})

export { getAdmins, createAdmin, updateAdminRoles, deleteAdmin, getRoles }

import React from 'react'
import GoogleAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Result,
  GooglePlacesAutocompleteProps,
} from 'react-google-places-autocomplete'
import 'react-google-places-autocomplete/dist/assets/index.css'
import TextField from '@material-ui/core/TextField'

import { OnSelect } from './types'
import { useStyles } from './styles'

type Props = {
  onSelect: OnSelect
  required?: boolean
} & Omit<GooglePlacesAutocompleteProps, 'onSelect'>

const GooglePlacesAutocomplete: React.FC<Props> = ({
  onSelect,
  loader,
  placeholder,
  required,
  ...restProps
}) => {
  const classes = useStyles()

  const onSelectPlace = async (result: Result) => {
    const results = await geocodeByAddress(result.description)
    const { lat, lng } = await getLatLng(results[0])
    onSelect({
      lat,
      lng,
      address: result.description,
    })
  }

  return (
    <GoogleAutocomplete
      autocompletionRequest={{
        componentRestrictions: {
          country: 'AR',
        },
      }}
      renderInput={inputProps => (
        <TextField
          {...inputProps}
          fullWidth
          label="Dirección"
          autoComplete="off"
          required={required}
        />
      )}
      loader={loader ?? 'Buscando...'}
      placeholder={placeholder ?? ''}
      onSelect={onSelectPlace}
      suggestionsClassNames={{
        container: classes.suggestionsContainer,
        suggestion: classes.suggestion,
      }}
      {...restProps}
    />
  )
}

export { GooglePlacesAutocomplete }
